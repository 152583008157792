import React, { useEffect, useState } from 'react';
import coin from "../../coin.png";
import gift1 from './gift1.png';
import gift2 from './gift2.png';
import Back from '../Back';
import './friends.scss';
import { getInviteLink, getFriendsList } from '../../http';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
import invite from "./invite.png";
import { formatNumber } from '../../Utils/formatNumber';


export const Frens = () => {

    function openLink(link) {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink(link);
        } 
    }
    
    const { t } = useTranslation();
    const [friendList, setFriendList] = useState([]);
    const [inviteLink, setInviteLink] = useState("");

    const fetchFriends = async () => {
        try {
            const data = await getFriendsList();
            if (data && Array.isArray(data.friends)) {
                setFriendList(data.friends);
            } else {
                setFriendList([]);
            }
        } catch (error) {
            console.error('Failed to fetch friends:', error);
            setFriendList([]);
        }
    };

    const getInviteLinkFunc = async () => {
        const data = await getInviteLink();
        if (data) {
            setInviteLink(data.link);
        }
    };

    useEffect(() => {
        fetchFriends();
        getInviteLinkFunc();
    }, []);

    const handleClick = () => {
        openLink(inviteLink)
    };

    return (
        <div className='outer_container_friends'>
            <div className='container_glow_friends'>

            
                <Back />
                <div className="tab-header">
                    <h2 className="tab-title">Invite Friends</h2>
                    <div>You and your friend will receive bonuses</div>
                </div>
                <div className="tab_block_friends">
                    <div className="tab-subblock task">
                        <img src={gift1} className="frens-mainblock-img" />
                        <div>
                            <div className="tab-block-title name">{t('friends.addFriend')}</div>
                            <div className='row'>
                                <div className='flex_center reword'>
                                    {t('friends.you', { count: 15000 })}<img src={coin} className="coin_icon_task" alt="Coin-icon" />
                                   
                                    </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="tab-subblock task">
                        <img src={gift2} className="frens-mainblock-img" />
                        <div>
                            <div className="tab-block-title">{t('friends.addPremiumFriend')}</div>
                            <div className='row'>
                                <div className='flex_center reword'>
                                    {t('friends.you', { count: 75000 })}<img src={coin} className="coin_icon_task" alt="Coin-icon" />
                                   
                                    </div>
                            
                            </div>
                        </div>
                    </div>
                </div>

                <div className='friends_reload_container'>List of your friends </div>
                <div className='friends'>
                    {friendList.length > 0 ? (
                        <div className='friends_column'>
                            {friendList.map(friend => (
                                <div className='friends_row'>
                                    <div className="friend">
                                        <img className='friend_img' src={friend.photo} alt={friend.name} />
                                        <div className="friend_info">
                                            <div>{friend.name}</div>
                                            <div className="friend_progress">
                                                <img src={coin} alt="Coin-icon" />
                                                <p className="friend_networth">{formatNumber(friend.total_coins_earned)}</p>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            ))}
                            <div className="kostil" />
                        </div>
                    ) : (
                        <div className='warning'>You have not invited anyone yet.</div>
                    )}
                </div>
                    <div className='invite_link' onClick={() => handleClick()}>
                        <div className='containet_text_ing_friends'>Invite a friend<img src={invite} alt="Invite icon" /></div>
                    </div>

                <Footer />
            </div>
        </div>
    );
};

import React, { useState } from 'react';
import "./WalletConnect.scss";
import { updatePlayerWalletAddress } from '../../http';
import { useAppData } from '../../AppContext';
function WalletConnect({title, description, onClick, avaliable, wallet_address}) {
    const [walletAddress, setWalletAddress] = useState(wallet_address.length > 0 ? wallet_address : '');
    const {setNotification} = useAppData()
    const handleAccept = async () => {
        await updatePlayerWalletAddress()
        handleDecline()

    };

    const handleDecline = () => {
        setNotification([])
        onClick()
    };

    return (
        <div className='outer_container_wallet'>
            {avaliable ? 
            <>
               <div className='content_wallet'>
                <div className='title_wallet'>{title}</div>
                <div className='subtext_wallet'>{description}</div>

                <input 
                    type="text" 
                    value={walletAddress} 
                    onChange={(e) => setWalletAddress(e.target.value)} 
                    placeholder="Enter your wallet address"
                    className='input_wallet'
                />

            </div>
            <div className="button_container">

                <button className='button_thanks' onClick={handleAccept}>
                    Accept
                </button>

                <button className='button_decline' onClick={handleDecline}>
                    Decline
                </button>

                
            </div>
            </>
            :
            <>
            <div className='content_wallet'>
                <div className='title_wallet'>You have already entered your wallet</div>
                <input 
                    type="text" 
                    value={walletAddress} 
                    onChange={(e) => setWalletAddress(e.target.value)} 
                    placeholder="Enter your wallet address"
                    className='input_wallet'
                    disabled
                />

            </div>
            <button className='button_thanks' onClick={handleDecline}>
                    Ok
                </button>
            </>
            
            }
            
        </div>
    );
}

export default WalletConnect;

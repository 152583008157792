import React, { useState, useEffect } from 'react';
import './instruction.scss';

function Countdown() {
    const calculateTimeLeft = () => {
        const difference = +new Date('2024-10-19T00:00:00') - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    return (
        <div className="countdown">
            {Object.keys(timeLeft).length > 0 ? (
                Object.keys(timeLeft).map((interval) => (
                    <div key={interval} className="countdown-item">
                        <div className="countdown-time">{timeLeft[interval]}</div>
                        <div className="countdown-label">{interval}</div>
                    </div>
                ))
            ) : (
                <div className="countdown-item">Time's up!</div>
            )}
        </div>
    );
}

export default Countdown;
